$(document).ready(function(){
	var api = new apiInterface();
	var quote_id = $('#lead_script').data("quoteid");
	var bank_validator = new bankValidator();
	$('.script').on('click','#direct-debit-completed',function(){
		var script_element = $(this).closest('.script');
		var script_name = $(script_element).data('script-name');
		var key = 'lead_script';
		if(isset(window[script_name + '_script'])){
			key = script_name + '_script';
		}
		$(".error-notice").html('');
		var that = $(this);
		var update = false;
		var script_ident = $(script_element).find('#active_step').data('script-ident');

		var quote_to_set = quote_id;
		if($(this).data('sales-order-id')){
			quote_to_set = $(this).data('sales-order-id');
		}

		if($('#using-stored-details').val()){
			

			var selected_option = $('#account-history option:selected');
			if($(selected_option).data('account-name-error') && !$('.account-name-error-div').is(':visible')){
				error_message('The current account name is too long, please select a new one.');
				$('.account-name-error-div').show();
				return false;
			}

			var data = {};
			data['account_id'] = $('#account-history').val();
			if($('#payment-date').length && $('#payment-date').is(':visible')) {
				data['payment-date'] = $('#payment-date').val();
				if (!(data['payment-date'] >= 1) && !(data['payment-date'] <= 31)) {
					error_message('You must provide a payment day')
					return;
				}
			}

			if($('#account-name-new').length && $('#account-name-new').is(':visible')) {
				var account_name = $('#account-name-new').val();
				if(account_name.length > 25 || account_name.length < 2) {
					error_message('Account Name MUST be less than or equal to 18 characters and more than 1!')
					return;
				}
				else {
					data['account_name'] = account_name;
				}
			}
			data['update'] = update;
			data['script_step'] = script_ident;

			api.use_stored_bank_details(quote_to_set, data, function(response){
				if(response.success === true){
					if($(that).data('success-step')){
						window[key].handleStep($(that).data('success-step'))
					}
					else{
						window[key].handleStep(window.lead_script.magicPlease('confirm_switch'));
					}
				}
				else{
					alert(response.message);
				}
			});
		}
		else{
			bank_validator.validateAll(function(errors,data){
				if(errors !== null){
					$.each(errors,function(index,error){
						$('#'+error.target).html(error.message);
					});
				}
				else{
					showLoader();
					data.push({'field' : 'update', 'value' : update});
					data.push({'field' : 'script_step', 'value' : script_ident});
					api.send_bank_details(quote_to_set, data,function(response){
						hideLoader();
						if(response.success === true){
							if($(that).data('success-step')){
								window[key].handleStep($(that).data('success-step'))
							}
							else{
								window[key].handleStep(window[key].magicPlease('confirm_switch'));
							}
						}
						else{
							alert(response.message);
						}
						hideLoader();
					});

				}
			});
		}
	});

	$('.script').on('click','#save-new-account-details',function(){
		var script_element = $(this).closest('.script');
		var script_name = $(script_element).data('script-name');
		var key = 'lead_script';
		if(isset(window[script_name + '_script'])){
			key = script_name + '_script';
		}
		$(".error-notice").html('');
		var that = $(this);
		var update = false;
		var script_ident = $(script_element).find('#active_step').data('script-ident');

		var quote_to_set = quote_id;
		if($(this).data('sales-order-id')){
			quote_to_set = $(this).data('sales-order-id');
		}

		
		bank_validator.validateAll(function(errors,data){
			//console.log(errors);
			//console.log(data);
			//console.log(results);
			if(errors !== null){
				$.each(errors,function(index,error){
					$('#'+error.target).html(error.message);
					//console.log(error.message);
				});
			}
			else{
				showLoader();
				data.push({'field' : 'update', 'value' : update});
				data.push({'field' : 'script_step', 'value' : script_ident});
				api.send_bank_details(quote_to_set, data,function(response){
					if(response.success === true){
						// rebuild the inline dd section. 
						makeRequest('/quote/get-inline-dd-html/'+quote_to_set,{},function(response){
							hideLoader();
							if(isset(response.success) && response.success && isset(response.direct_debit_html)){
								$('.direct-debit-information-dynamic-text').html(response.direct_debit_html);
								$('.direct-debit-information-section').removeClass('hide');
								$('#using-stored-details').val(1);
								$('.inline-checkout-go-to-backet-button').removeClass('hide');
							}
						},'GET');
					}
					else{
						alert(response.message);
					}
					hideLoader();
				});

			}
		});
		
	});

	$('.script').on('click','#payment-plan-direct-debit-completed',function(){
		var script_element = $(this).closest('.script');
		var script_name = $(script_element).data('script-name');
		var key = 'lead_script';
		if(isset(window[script_name + '_script'])){
			key = script_name + '_script';
		}
		$(".error-notice").html('');
		var that = $(this);
		var update = false;
		var script_ident = $(script_element).find('#active_step').data('script-ident');

		var quote_to_set = quote_id;
		if($(this).data('sales-order-id')){
			quote_to_set = $(this).data('sales-order-id');
		}

		var payment_plan_container = $('.payment-plan-payments-section');
		var number_of_payments = $(payment_plan_container).find('input[name="number_of_payments"]').val();
		var monthly_payment_amount = $(payment_plan_container).find('.monthly-payment-amount').data('monthly_payment_amount');

		if($('#using-stored-details').val()){
			

			var selected_option = $('#account-history option:selected');
			if($(selected_option).data('account-name-error') && !$('.account-name-error-div').is(':visible')){
				error_message('The current account name is too long, please select a new one.');
				$('.account-name-error-div').show();
				return false;
			}

			var data = {};
			data['account_id'] = $('#account-history').val();
			if($('#payment-date').length && $('#payment-date').is(':visible')) {
				data['payment-date'] = $('#payment-date').val();
				if (!(data['payment-date'] >= 1) && !(data['payment-date'] <= 31)) {
					error_message('You must provide a payment day')
					return;
				}
			}

			if($('#account-name-new').length && $('#account-name-new').is(':visible')) {
				var account_name = $('#account-name-new').val();
				if(account_name.length > 25 || account_name.length < 2) {
					error_message('Account Name MUST be less than or equal to 18 characters and more than 1!')
					return;
				}
				else {
					data['account_name'] = account_name;
				}
			}
			data['update'] = update;
			data['script_step'] = script_ident;

			var payment_plan_container = $('.payment-plan-payments-section');
			data['number_of_payments'] = number_of_payments;
			data['monthly_payment_amount'] = monthly_payment_amount;

			api.create_will_payment_plan_with_existing_details(quote_to_set, data, function(response){
				if(response.success === true){
					if($(that).data('success-step')){
						window[key].handleStep($(that).data('success-step'))
					}
					else{
						window[key].handleStep(window.lead_script.magicPlease('confirm_switch'));
					}
				}
				else{
					alert(response.message);
				}
			});
		}
		else{
			bank_validator.validateAll(function(errors,data){
				//console.log(errors);
				//console.log(data);
				//console.log(results);
				if(errors !== null){
					$.each(errors,function(index,error){
						$('#'+error.target).html(error.message);
						//console.log(error.message);
					});
				}
				else{
					showLoader();
					data.push({'field' : 'update', 'value' : update});
					data.push({'field' : 'script_step', 'value' : script_ident});
					data.push({'field' : 'number_of_payments', 'value' : number_of_payments});
					data.push({'field' : 'monthly_payment_amount', 'value' : monthly_payment_amount});
					
					api.create_will_payment_plan(quote_to_set, data,function(response){
						if(response.success === true){
							if($(that).data('success-step')){
								window[key].handleStep($(that).data('success-step'))
							}
							else{
								window[key].handleStep(window[key].magicPlease('confirm_switch'));
							}
						}
						else{
							alert(response.message);
						}
						hideLoader();
					});

				}
			});
		}
	});

	$('.script').on('click','#bank-payment-deposit-completed',function(){
		var script_element = $(this).closest('.script');
		var script_name = $(script_element).data('script-name');
		var key = 'lead_script';
		if(isset(window[script_name + '_script'])){
			key = script_name + '_script';
		}
		$(".error-notice").html('');
		var that = $(this);
		var update = false;
		var script_ident = $(script_element).find('#active_step').data('script-ident');

		var quote_to_set = quote_id;
		if($(this).data('sales-order-id')){
			quote_to_set = $(this).data('sales-order-id');
		}

		var bank_payment_container = $('.bank-payments-section');
		var number_of_payments = 1;
		var deposit_amount = $(bank_payment_container).find(':input[name="deposit_amount"]').val();

		if($('#using-stored-details').val()){
			

			var selected_option = $('#account-history option:selected');
			if($(selected_option).data('account-name-error') && !$('.account-name-error-div').is(':visible')){
				error_message('The current account name is too long, please select a new one.');
				$('.account-name-error-div').show();
				return false;
			}

			var data = {};
			data['account_id'] = $('#account-history').val();
			if($('#payment-date').length && $('#payment-date').is(':visible')) {
				data['payment-date'] = $('#payment-date').val();
				if (!(data['payment-date'] >= 1) && !(data['payment-date'] <= 31)) {
					error_message('You must provide a payment day')
					return;
				}
			}

			if($('#account-name-new').length && $('#account-name-new').is(':visible')) {
				var account_name = $('#account-name-new').val();
				if(account_name.length > 25 || account_name.length < 2) {
					error_message('Account Name MUST be less than or equal to 18 characters and more than 1!')
					return;
				}
				else {
					data['account_name'] = account_name;
				}
			}
			data['update'] = update;
			data['script_step'] = script_ident;

			var bank_payment_container = $('.bank-payments-section');
			data['number_of_payments'] = number_of_payments;
			data['deposit_amount'] = deposit_amount;

			api.create_will_direct_debit_deposit_with_existing_details(quote_to_set, data, function(response){
				if(response.success === true){
					if($(that).data('success-step')){
						window[key].handleStep($(that).data('success-step'))
					}
					else{
						window[key].handleStep(window.lead_script.magicPlease('confirm_switch'));
					}
				}
				else{
					alert(response.message);
				}
			});
		}
		else{
			bank_validator.validateAll(function(errors,data){
				//console.log(errors);
				//console.log(data);
				//console.log(results);
				if(errors !== null){
					$.each(errors,function(index,error){
						$('#'+error.target).html(error.message);
						//console.log(error.message);
					});
				}
				else{
					showLoader();
					data.push({'field' : 'update', 'value' : update});
					data.push({'field' : 'script_step', 'value' : script_ident});
					data.push({'field' : 'number_of_payments', 'value' : number_of_payments});
					data.push({'field' : 'deposit_amount', 'value' : deposit_amount});
					
					api.create_will_direct_debit_deposit(quote_to_set, data,function(response){
						if(response.success === true){
							if($(that).data('success-step')){
								window[key].handleStep($(that).data('success-step'))
							}
							else{
								window[key].handleStep(window[key].magicPlease('confirm_switch'));
							}
						}
						else{
							alert(response.message);
						}
						hideLoader();
					});

				}
			});
		}
	});

	$('.script').on('click', '.finance-agreement-account-select .show-account-button', function (e) {
		
		e.preventDefault();
		var container = $(this).closest('.finance-agreement-account-select');
		var data_container = $(container).find('.finance-dd-data-holder');
		var loader = $(container).find('.finance-dd-data-loader');
		if($(data_container).is(':visible')){
			$(this).text('Hide Details');
			$(data_container).hide();
			return;
		}
		else {
			$(this).text('Show Details');
		}


		var value = $(container).find('select[name="account_history"] option:selected').val();
		if(!value){
			return false;
		}
		
		var contact_id = $(container).data('contact_id');
		if(!contact_id){
			error_message('contact id not found - please refresh and try again');
			return false;
		}

		var loader = $(container).find('.finance-dd-data-loader');
		loader.show();

		
		$(data_container).hide();

		var data = {
			'account_id' : value,
			'contact_id' : contact_id
		}

		makeRequest('/contact/view/account', data, function(response){
			if(isset(response.success) && response.success){
				$(data_container).html(response.html);
				$(data_container).show();
			}
			else{
				var error = 'unable to load details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			loader.hide();
		});
		

	});

	$('#client-profile').on('click', '.client-profile-account-select .show-account-button', function (e) {
		
		e.preventDefault();
		var container = $(this).closest('.client-profile-account-select');
		var data_container = $(container).find('.client-profile-dd-data-holder');
		var loader = $(container).find('.client-profile-dd-data-loader');
		if($(data_container).is(':visible')){
			$(this).text('Hide Details');
			$(data_container).hide();
			return;
		}
		else {
			$(this).text('Show Details');
		}


		var value = $(container).find('select[name="account_history"] option:selected').val();
		if(!value){
			return false;
		}
		
		var contact_id = $(container).data('contact_id');
		if(!contact_id){
			error_message('contact id not found - please refresh and try again');
			return false;
		}

		var loader = $(container).find('.client-profile-dd-data-loader');
		loader.show();

		
		$(data_container).hide();

		var data = {
			'account_id' : value,
			'contact_id' : contact_id
		}

		makeRequest('/contact/view/account', data, function(response){
			if(isset(response.success) && response.success){
				$(data_container).html(response.html);
				$(data_container).show();
			}
			else{
				var error = 'unable to load details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			loader.hide();
		});
		

	});

	

	$('.script').on('click','#finance-agreement-direct-debit-completed',function(){
		var script_element = $(this).closest('.script');
		var script_name = $(script_element).data('script-name');
		var key = 'lead_script';
		if(isset(window[script_name + '_script'])){
			key = script_name + '_script';
		}
		$(".error-notice").html('');
		var that = $(this);
		var update = false;
		var script_ident = $(script_element).find('#active_step').data('script-ident');

		var quote_to_set = quote_id;
		if($(this).data('sales-order-id')){
			quote_to_set = $(this).data('sales-order-id');
		}

		var payment_plan_container = $('.finance-agreement-payments-section');
		var number_of_payments = $(payment_plan_container).find('select[name="number_of_payments"]').val();
		var monthly_payment_amount = $(payment_plan_container).find('.monthly-payment-amount').data('monthly_payment_amount');
		var finance_penny_value = $(payment_plan_container).find('.total-balance').data('value')
		// get out of penny value here
		var finance_total = finance_penny_value/100;

		// var finance_reference = $('#finance-reference').val();
		// if(finance_reference.length !== 10){
		// 	// add some more sensible checking here
		// 	error_message('Finance Reference MUST be Added!')
		// 	return;
		// }

		if($('#using-stored-details').val()){
			
			var selected_option = $('#account-history option:selected');
			if($(selected_option).data('account-name-error') && !$('.account-name-error-div').is(':visible')){
				error_message('The current account name is too long, please select a new one.');
				$('.account-name-error-div').show();
				return false;
			}

			var data = {};
			data['account_id'] = $('#account-history').val();
			if($('#payment-date').length && $('#payment-date').is(':visible')) {
				var payment_date = $('#payment-date').val();
				data['payment_date'] = payment_date;
				if (!(data['payment_date'] >= 1) && !(data['payment_date'] <= 31)) {
					error_message('You must provide a payment day')
					return;
				}
			}

			if($('#account-name-new').length && $('#account-name-new').is(':visible')) {
				var account_name = $('#account-name-new').val();
				if(account_name.length > 25 || account_name.length < 2) {
					error_message('Account Name MUST be less than or equal to 18 characters and more than 1!')
					return;
				}
				else {
					data['account_name'] = account_name;
				}
			}
			data['update'] = update;
			data['script_step'] = script_ident;

			var payment_plan_container = $('.payment-plan-payments-section');
			data['number_of_payments'] = number_of_payments;
			data['monthly_payment_amount'] = monthly_payment_amount;
			// data['finance_reference'] = finance_reference;
			data['finance_total'] = finance_total; 

			api.create_will_finance_agreement_with_existing_details(quote_to_set, data, function(response){
				if(response.success === true){
					if($(that).data('success-step')){
						window[key].handleStep($(that).data('success-step'))
					}
					else{
						window[key].handleStep(window.lead_script.magicPlease('confirm_switch'));
					}
				}
				else{
					alert(response.message);
				}
			});
		}
		else{

			bank_validator.validateAll(function(errors,data){
				//console.log(errors);
				//console.log(data);
				//console.log(results);
				if(errors !== null){
					$.each(errors,function(index,error){
						$('#'+error.target).html(error.message);
						//console.log(error.message);
					});
				}
				else{
					showLoader();
					console.log(data);
					data.push({'field' : 'update', 'value' : update});
					data.push({'field' : 'script_step', 'value' : script_ident});
					data.push({'field' : 'number_of_payments', 'value' : number_of_payments});
					data.push({'field' : 'monthly_payment_amount', 'value' : monthly_payment_amount});
					// data.push({'field' : 'finance_reference', 'value' : finance_reference});
					data.push({'field' : 'finance_total', 'value' : finance_total});

					console.log(data);
					api.create_will_finance_agreement(quote_to_set, data,function(response){
						if(response.success === true){
							if($(that).data('success-step')){
								window[key].handleStep($(that).data('success-step'))
							}
							else{
								window[key].handleStep(window[key].magicPlease('confirm_switch'));
							}
						}
						else{
							alert(response.message);
						}
						hideLoader();
					});

				}
			});
		}
	});

	$('.script').on('click','.skip-dd-inline-button-wrapper button',function(){
		$('.skip-dd-wrapper').find('input[name="skip_dd_flag"]').val(1);
		$('.inline-direct-debit-input-wrapper').addClass('hide');
		$('.skip-dd-text').removeClass('hide');
		$('.undo-skip-inline-dd-button-wrapper').removeClass('hide');
		$('.skip-dd-inline-button-wrapper').addClass('hide');
		$('.inline-direct-debit-script-text').addClass('hide');
	});
	$('.script').on('click','.undo-skip-inline-dd-button-wrapper button',function(){
		$('.skip-dd-wrapper').find('input[name="skip_dd_flag"]').val(0);
		$('.inline-direct-debit-input-wrapper').removeClass('hide');
		$('.skip-dd-text').addClass('hide');
		$('.undo-skip-inline-dd-button-wrapper').addClass('hide');
		$('.skip-dd-inline-button-wrapper').removeClass('hide');
		$('.inline-direct-debit-script-text').removeClass('hide');
	});
	
	$('.script').on('click','#direct-debit-skip',function(){
		var script_element = $(this).closest('.script');
		var script_name = $(script_element).data('script-name');
		var key = 'lead_script';
		if(isset(window[script_name + '_script'])){
			key = script_name + '_script';
		}
		$(".error-notice").html('');
		var that = $(this);
		
		var quote_to_set = quote_id;
		if($(this).data('sales-order-id')){
			quote_to_set = $(this).data('sales-order-id');
		}
		
		makeRequest('/api/skip-dd', {'quote_id' : quote_to_set}, function(response){
			if(isset(response.success) && response.success){
				if($(that).data('success-step')){
					window[key].handleStep($(that).data('success-step'))
				}
				else{
					window[key].handleStep(window.lead_script.magicPlease('confirm_switch'));
				}
			}
			else{
				var error = 'unable to skip dd - please try taking it';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});
	
	$('.script, #modal_container').on('click','#new-stored-account-details',function(){
		// show the inputs 
		$('div.bank-details-show-new').show();
		$('#stored_details_row').hide();
		$('#new-bank-section').show();
		$('#using-stored-details').val('');
		$('.inline-direct-debit-input-wrapper').find('#stored_details_row').hide();
		$('.inline-direct-debit-input-wrapper').find('.direct-debit-button-container').show();
		$('.inline-checkout-go-to-backet-button').addClass('hide');
		/*$('#sort_code_row').hide();
		$('#account_number_row').hide();
		$('#account_holder_row').hide();
		$('#undo-stored-account-details').show();
		$('#use-stored-account-details').hide();
		$('#using-stored-details').val(1);
		$('#account-history').prop('disabled', 'disabled');*/

	});
	
	$('.script, #modal_container').on('click','#undo-stored-account-details',function(){
		$(".error-notice").html('');
		// hide the inputs 
		$('div.bank-details-show-new').hide();
		$('#using-stored-details').val(1);
		$('#stored_details_row').show();
		$('#new-bank-section').hide();
		$('.inline-direct-debit-input-wrapper').find('#stored_details_row').show();
		$('.inline-direct-debit-input-wrapper').find('.direct-debit-button-container').hide();
		$('.inline-checkout-go-to-backet-button').removeClass('hide');
	});
	
});
